import { useCreditCardValidator, images } from 'react-creditcard-validator';
import '../Components/css/test.scss'
const CardComponent = ({
    setCardCvv,
    setCardDate,
    cardDate,
    setCardNumber, }) => {


    const handleCvcChange = (e) => {
        setCardCvv(e.target.value)
    };
    const handleDateChange = (e, e2) => {
        const value = e.target.value;

        // Remove any character that isn't a number
        const cleanedValue = value.replace(/[^0-9]/g, '');

        // Format the cleaned numbers
        let formattedValue = cleanedValue;

        // Add "/" after 2 digits
        if (cleanedValue.length >= 2) {
            formattedValue = `${cleanedValue.substr(0, 2)}/${cleanedValue.substr(2, 2)}`;
        }

        // Trim to MM/YY format
        if (formattedValue.length > 5) {
            formattedValue = formattedValue.substr(0, 5);
        }

        setCardDate(formattedValue);
    };
    const handleCardNumber = (e) => {
        setCardNumber(e)
    };
    const {
        getCardNumberProps,
        getCardImageProps,
        meta: { erroredInputs },
    } = useCreditCardValidator({ cardNumberValidator: handleCardNumber });
    return (
        <main>
            <div>
                <div className="input-group">
                    <svg {...getCardImageProps({ images })} />
                    <label>Numero de Tarjeta</label>
                    <input {...getCardNumberProps()} />
                </div>
                <small>{(erroredInputs.cardNumber && erroredInputs.cardNumber) && 'Formato de tarjeta incorrecto'}</small>
                <div className="multi-input">
                    <div className="input-group">
                        <label>Fecha de Expiración</label>
                        <input
                            value={cardDate}
                            onChange={handleDateChange}
                            type="text"
                            placeholder="MM/AA"
                            maxLength="5"
                            pattern="(0[1-9]|1[0-2])\/[0-9]{2}"
                            className="inputReal"
                        />
                        <small>{(erroredInputs.expiryDate && erroredInputs.expiryDate) && 'Formato de fecha incorrectos'}</small>
                    </div>
                    <div className="input-group">
                        <label>CVV</label>
                        <input onChange={handleCvcChange} />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CardComponent;
