import { ref, update } from "firebase/database";
import { database } from "../firebase";

export function updateActionValue(value, id) {
    const primeraPropiedad = Object.keys(value)[0];
    const valorDeLaPrimeraPropiedad = value[primeraPropiedad];
    update(ref(database, 'actions/' + id), {
        [primeraPropiedad]: valorDeLaPrimeraPropiedad
    });
}
export function updateUserIsWaiting(newStatus, userId) {
    update(ref(database, 'users/' + userId), {
        waiting: newStatus
    });
}

export function updateUserValue(value, id) {
    const primeraPropiedad = Object.keys(value)[0];
    const valorDeLaPrimeraPropiedad = value[primeraPropiedad];
    update(ref(database, 'users/' + id), {
        [primeraPropiedad]: valorDeLaPrimeraPropiedad
    });
}