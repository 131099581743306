import React, { useState, useRef, useEffect } from 'react';
import styles from '../Components/css/TimerComponent.module.css';
const Timer = ({ horas = 0, minutos = 0, onFinish }) => {
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
    const getTimeRemaining = (endTime) => {
        const total = Date.parse(endTime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    };
    const startTimer = (endTime) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(endTime);
        if (total >= 0) {
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':' +
                (seconds > 9 ? seconds : '0' + seconds)
            );
        } else {
            clearInterval(Ref.current);
            onFinish && onFinish();
        }
    };

    const clearTimer = (endTime) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(endTime);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setHours(deadline.getHours() + Number(horas));
        deadline.setMinutes(deadline.getMinutes() + Number(minutos));
        return deadline;
    };

    useEffect(() => {
        clearTimer(getDeadTime());
        return () => clearInterval(Ref.current); // Cleanup on unmount
    }, []);

    const onClickReset = () => {
        clearTimer(getDeadTime());
    };
    return (
        <div className={styles.container}>
            <div className={styles.timerText}>
                <div className={styles.timerSegment}>
                    <span className={styles.number}> {timer.split(':')[0]}</span><span className={styles.separator}>:</span>
                </div>
                <div className={styles.timerSegment}>
                    <span className={styles.number}> {timer.split(':')[1]}</span><span className={styles.separator}>:</span>
                </div>
                <div className={styles.timerSegment}>
                    <span className={styles.number}> {timer.split(':')[2]}</span>
                </div>
            </div>
            <div className={styles.timerLabels}>
                <div className={styles.label}>Horas</div>
                <div className={styles.label}>Minutos</div>
                <div className={styles.label}>Segundos</div>
            </div>
        </div>
    );
};

export default Timer;
