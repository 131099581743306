import { useState, useEffect } from 'react';

export function useTextoAnimado(textoCompleto) {
    const [textoVisible, setTextoVisible] = useState('');

    useEffect(() => {
        let idx = 0;
        const interval = setInterval(() => {
            if (idx < textoCompleto.length) {
                setTextoVisible(prev => prev + textoCompleto[idx]);
                idx++;
            } else {
                setTextoVisible(''); // Reiniciar el texto.
                idx = 0;
            }
        }, 100);
        return () => clearInterval(interval);
    }, [textoCompleto]); // Agregamos textoCompleto como dependencia

    return textoVisible;
}
