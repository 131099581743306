import React, { useState } from 'react';
import './WindowTest.css';

import sslPadlock from '../imgs/ssl.svg';
const WindowComponent = ({ children, title, dominio, url, logo, iframeUrl, height = '559px' }) => {
    const [isEnlarged, setIsEnlarged] = useState(false);
    const toggleEnlarge = () => {
        setIsEnlarged(!isEnlarged);
    };

    const handleMouseOver = (element, color, cursor) => {
        element.style.backgroundColor = color;
        element.style.cursor = cursor;
    };

    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>
                <div id="window" style={{ height: height }} >
                    <div>
                        <div id="title-bar">
                            <div style={{ marginTop: '5px' }}>
                                <img src={logo} style={{ objectFit: 'contain', marginRight: '5px', }} width="16px" height="16px" alt="Logo" id="logo" />
                                <span id="logo-description">{title}</span>
                            </div>
                            <div>
                                <span
                                    id="minimize"
                                    onMouseOver={() => handleMouseOver(document.getElementById("minimize"), '#272727', 'context-menu')}
                                    onMouseOut={() => handleMouseOver(document.getElementById("minimize"), 'black', 'default')}
                                >&#8212;</span>
                                <span
                                    id="square"
                                    onMouseOver={() => handleMouseOver(document.getElementById("square"), '#272727', 'context-menu')}
                                    onMouseOut={() => handleMouseOver(document.getElementById("square"), 'black', 'default')}
                                    onClick={toggleEnlarge}
                                >□</span>
                                <span
                                    id="exit"
                                    onMouseOver={() => handleMouseOver(document.getElementById("exit"), 'red', 'context-menu')}
                                    onMouseOut={() => handleMouseOver(document.getElementById("exit"), 'black', 'default')}
                                >✕</span>
                            </div>
                        </div>
                        <div id="url-bar">
                            <img style={{ fill: "red" }} src={sslPadlock} width="35px" height="35px" alt="SSL" id="ssl-padlock" />
                            <span id="domain-name">{dominio}</span>
                            <span id="domain-path">{url}</span>
                        </div>
                    </div>
                    {iframeUrl && <iframe style={{ maxWidth: '100%' }} id="content" src={iframeUrl}></iframe>}
                    {children}
                </div>

            </div>

        </>

    );
}

export default WindowComponent;
